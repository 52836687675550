<template>
  <div>
    <div class="completion">
      <font-awesome-icon
        :icon="hideProgress ? 'chevron-down' : 'chevron-up'"
        class="completion__close"
        @click="hideProgress = !hideProgress"
      />
      <h2>Completion progress</h2>
      <p>Please complete all required steps before proceeding.</p>
      <div class="completion__progress">
        <span>{{ completedLength }} / {{ checkList.length }} steps</span>
        <div class="completion__progress-inner">
          <i :style="{ width: progress + '%' }"></i>
        </div>
      </div>
      <ol v-if="hideProgress">
        <li
          v-for="item in checkList"
          :key="item.quizName"
          :class="{ completed: item.completed }"
        >
          <font-awesome-icon icon="check-circle" />{{ item.quizName }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompletionProgress",
  props: {
    checkList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      hideProgress: false,
    };
  },
  computed: {
    progress() {
      if (this.checkList.length > 0) {
        let completedNum = this.checkList.filter(
          (quiz) => quiz.completed
        ).length;
        return Math.round((completedNum / this.checkList.length) * 100);
      } else {
        return 0;
      }
    },
    completedLength() {
      return this.checkList.filter((quiz) => quiz.completed).length;
    },
  },
  created() {
    if (window.innerWidth > 1180) {
      this.hideProgress = false;
    } else {
      this.hideProgress = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.completion {
  background: #fff;
  border-radius: 4px;
  position: fixed;
  left: 435px;
  bottom: 40px;
  padding: 15px 17px 0;
  width: 320px;
  z-index: 998;
  display: block;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.17);
  @media screen and (max-width: 1180px) {
    display: none;
  }
  .progress-nav-closed & {
    left: 283px;
  }
  &.is-active {
    display: block;
  }
  &.is-hide {
    display: none;
  }
  &__robot {
    height: 65px;
    margin: 0 auto;
    display: block;
    margin-bottom: 9px;
  }
  h2 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
  }
  p {
    text-align: center;
    color: #968e8e;
    margin-bottom: 20px;
    color: rgba(28, 28, 28, 0.4);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.28px;
  }
  &__progress {
    align-items: flex-start;
    position: relative;
    display: flex;
    flex-direction: column;
    > span {
      font-size: 14px;
      padding: 6px 6px 5px;
      text-align: center;
      white-space: nowrap;
      background: #fefefe;
      transition: all 0.3s ease;
      font-weight: 500;
      color: var(--red);
      border-radius: 10px;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 8px;
      display: inline-flex;
    }
  }
  &__progress-inner {
    height: 12px;
    position: relative;
    background: #f6f6f6;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 18px;
    width: 100%;
    i {
      position: absolute;
      left: 0;
      height: 100%;
      background: var(--red);
      content: "";
      display: block;
      transition: width 0.3s ease;
    }
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--red);
    cursor: pointer;
  }
  ol {
    padding: 0;
    margin: 0 0 20px;
    text-align: left;
    counter-reset: item;
    list-style-type: none;
    li {
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      margin-bottom: 5px;
      color: #bcbcbc;
      counter-increment: item;
      position: relative;
      padding: 7px 10px 7px 57px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
      &.completed {
        color: #404040;
        svg {
          color: var(--red);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        position: absolute;
        left: 43px;
        top: 7px;
        content: counter(item) ".";
      }
      svg {
        position: absolute;
        left: 9px;
        top: 10px;
        font-size: 17px;
      }
    }
  }
}

.completion-cta {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 20px;
  bottom: 80px;
  padding: 8px 14px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  z-index: 999;
  img {
    width: 18px;
    margin-right: 7px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
